


import FETCHING from '../../library/fetching'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {

      form : {
        id : '',
        // nik : '',
        // nama_lengkap : '',
        tmp_lahir : '',
        tgl_lahir : '',
        jns_kelamin : '',
        alamat : '',
        dusun : '',
        rt_rw : '',
        kecamatan_id : '',
        // des_kel_id : '',
        telp_hp : '',
        email : '',
        kode_pos : '',
        status_perkawinan_id : '',
        uraian_perkawinan : '',
        agama_id : '',
        uraian_agama : '',
        tinggi_bdn : '',
        berat_bdn : '',
        file : null,
      },

      form1 : {
        id : '',
        biodata_id : '',
        nama_pendidikan : '',
        pendidikan_id : '',
        tahun_tamat : '',
        nem_ipk : '',
        jurusan : '',
        jurusan_id : '',
        pendidikan_formal : '',
        tipe_nilai : '',
        nilai_rata_ijazah : '',
        keterampilan_id : [],
        tahun_keterampilan : '',
      },

      formBahasa : {
        id : '',
        biodata_id : '',
        bahasa_id : '',
      },

      formKeterampilan : {
        id : '',
        biodata_id : '',
        keterampilan_id : '',
        tahun : '',
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      listKecamatan : '',
      listDesKel : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      


     
      list_data : [],
      list_pendidikan_formal : [],
      list_bahasa : [],
      list_keterampilan : [],

      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value : "",
      page_first1 : 1,
      page_last1 : 0,
      page_limit1 : 8,
      file_old : "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat : false,
      mdl_pendidikan_formal : false,
      mdl_pendidikan_formal_add : false,
      mdl_pendidikan_formal_edit : false,
      mdl_pendidikan_formal_remove : false,
      mdl_lampiran : false,
      mdl_bahasa : false,
      mdl_bahasa_add : false,
      mdl_keterampilan : false,
      mdl_keterampilan_add : false,
      btn_add: false,


      FETCHING : FETCHING,
      DATA_MASTER : DATA_MASTER,
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              pendidikan_id : this.form.pendidikan_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },

    selectData : function(data){
        this.form.id = data.id;
        this.form.nik = data.nik;
        // this.form.nama_lengkap = data.nama_lengkap;
        this.form.tmp_lahir = data.tmp_lahir;
        this.form.tgl_lahir = data.tgl_lahir;
        this.form.jns_kelamin = data.jns_kelamin;
        this.form.alamat = data.alamat;
        this.form.dusun = data.dusun;
        this.form.rt_rw = data.rt_rw;
        this.form.kecamatan_id = data.kecamatan_id;
        this.form.nama_kecamatan = data.nama_kecamatan;
        this.form.des_kel_id = data.des_kel_id;
        this.form.nama_des_kel = data.nama_des_kel;
        this.form.telp_hp = data.telp_hp;
        this.form.email = data.email;
        this.form.kode_pos = data.kode_pos;
        this.form.status_perkawinan_id = data.status_perkawinan_id;
        this.form.uraian_perkawinan = data.uraian_perkawinan;
        this.form.agama_id = data.agama_id;
        this.form.uraian_agama = data.uraian_agama;
        this.form.tinggi_bdn = data.tinggi_bdn;
        this.form.berat_bdn = data.berat_bdn;
        this.form.file = data.file;
        this.file_old = data.file;

        this.form.username = data.username;
        this.form.nama = data.nama;
        this.form.email = data.email;
    },

    getView1 : function(idku){
      // console.log(idku)
      this.form1.biodata_id = idku
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_PENDIDIKAN_FORMAL + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            biodata_id : this.form1.biodata_id,
            data_ke : this.page_first1,
            cari_value : this.cari_value1,
            page_limit : this.page_limit1,
          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.list_pendidikan_formal = res_data.data;
            this.page_last = res_data.jml_data;
            this.$store.commit("hideLoading");
            // console.log(res_data);
      });
    },

    addData1 : function() {
      var formData = new FormData();
      formData.append("biodata_id", this.form1.biodata_id);
      formData.append("nama_pendidikan", this.form1.nama_pendidikan);
      formData.append("pendidikan_id", this.form1.pendidikan_id);
      formData.append("jurusan_id", this.form1.jurusan_id);
      formData.append("tahun_tamat", this.form1.tahun_tamat);
      formData.append("nem_ipk", this.form1.nem_ipk);
      formData.append("nilai_rata_ijazah", this.form1.nilai_rata_ijazah);
      formData.append("keterampilan_id", this.form1.keterampilan_id);
      formData.append("tahun_keterampilan", this.form1.tahun_keterampilan);

      fetch(this.$store.state.url.URL_BIO_PENDIDIKAN_FORMAL + "addData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView1(this.form1.biodata_id);
      });
    },

    editData1: function() {
      var formData = new FormData();
      formData.append("id", this.form1.id);
      formData.append("biodata_id", this.form1.biodata_id);
      formData.append("nama_pendidikan", this.form1.nama_pendidikan);
      formData.append("pendidikan_id", this.form1.pendidikan_id);
      formData.append("jurusan_id", this.form1.jurusan_id);
      formData.append("tahun_tamat", this.form1.tahun_tamat);
      formData.append("nem_ipk", this.form1.nem_ipk);
      formData.append("nilai_rata_ijazah", this.form1.nilai_rata_ijazah);
      // console.log(this.penerima.bantuan_id);      
      fetch(this.$store.state.url.URL_BIO_PENDIDIKAN_FORMAL + "editData", {
        method: "POST",
        headers: {
          // "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
        this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
        this.getView1(this.form1.biodata_id);
      })
    },

    removeData1 : function(idnya, file){
      fetch(this.$store.state.url.URL_BIO_PENDIDIKAN_FORMAL + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id : idnya,
            file : file, 
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView1(this.form1.biodata_id);
      });

    },

    selectData1 : function(data){
      this.form1.id = data.id;
      this.form1.biodata_id = data.biodata_id;
      this.form1.nama_pendidikan = data.nama_pendidikan;
      this.form1.pendidikan_id = data.pendidikan_id;
      this.form1.tahun_tamat = data.tahun_tamat;
      this.form1.nem_ipk = data.nem_ipk;
      this.form1.jurusan_id = data.jurusan_id;
      this.form1.nilai_rata_ijazah = data.nilai_rata_ijazah;
      this.form1.file = data.file;
      this.file_old = data.file;
    },

    getViewKeterampilan: function(idku){
      // console.log(idku)
      this.formKeterampilan.biodata_id = idku
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_BIO_KETERAMPILAN + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            biodata_id : this.formKeterampilan.biodata_id,
            data_ke : this.page_first1,
            cari_value : this.cari_value1,
            page_limit : this.page_limit1,
          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.list_keterampilan = res_data.data;
            this.page_last = res_data.jml_data;
            this.$store.commit("hideLoading");
            // console.log(res_data);
      });
    },

    addDataKeterampilan : function() {
      fetch(this.$store.state.url.URL_BIO_KETERAMPILAN + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.formKeterampilan)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getViewKeterampilan(this.formKeterampilan.biodata_id);
      });
    },

    removeDataKeterampilan : function(idnya){
      fetch(this.$store.state.url.URL_BIO_KETERAMPILAN + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id : idnya
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getViewKeterampilan(this.formKeterampilan.biodata_id);
      });

    },

    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    // filter_kecamatan : function (val, update) {
    //     update(() => {
    //       if (val === '') {}
    //       else {FETCHING.getKecamatan(val)}
    //     })
    // },

    // filter_des_kel : function (kecamatan_id, update) {
    //     update(() => {
    //       if (kecamatan_id === '') {}
    //       else {FETCHING.getDesKel(this.form.kecamatan_id)}
    //     })
    // },

    autocomplete_db_filter : function (val, update) {
      update(() => {
        if (val === '') {}
        else {FETCHING.getContohAtocomplete(val)}
      })
    },

    autocomplete_pendidikan : function (val, update) {
      update( () => {
        if (val == '') {}
        else {
          FETCHING.getPendidikan(val)
        }
      })
    },

    autocomplete_jurusan : function (val, update) {
      update( () => {
        if (val == '') {}
        else {
          FETCHING.getJurusan(val)
        }
      })
    },

    autocomplete_keterampilan : function (val, update) {
      update( () => {
        if (val == '') {}
        else {
          FETCHING.getKeterampilan(val)
        }
      })

		// specific logic to eventually call done(...) -- or not
      //   done(val) <- INI YANG DIBUKA NAH UNTUK MULTIPLE NYA AUTOCOMPLETE
      // jangan lupa tambahkan "done" di functionnya

        // done callback has two optional parameters:
        //  - the value to be added
        //  - the behavior (same values of new-value-mode prop,
        //    and when it is specified it overrides that prop –
        //    if it is used); default behavior (if not using
        //    new-value-mode) is to add the value even if it would
        //    be a duplicate
    },
    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },
        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        async awaitFetch(){
          this.$store.state.list_jurusan = await this.DATA_MASTER.getJurusan(this.form1.pendidikan_id);
        }


    // ====================================== PAGINATE ====================================

  },

  mounted () {
    FETCHING.getContohAtocomplete('');
    FETCHING.getPendidikan('');
    FETCHING.getJurusan('');
    FETCHING.getKeterampilan('');
    DATA_MASTER.getAgama();
    DATA_MASTER.getStatusPerkawinan();
    DATA_MASTER.getKecamatan();
    DATA_MASTER.getBahasa();
    DATA_MASTER.getPendidikan();
    this.getView();
    this.getView1();
    this.getViewBahasa();
    this.getViewKeterampilan();
  },
}
